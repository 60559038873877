import {
  RICHTEXT_CE_NAME,
  RichTextContentElementProps,
} from "./richTextContentElement";

/**
 * RichTextContentElementStyleGlobal
 */
export default function RichTextContentElementStyleGlobal(
  props: RichTextContentElementProps
) {
  return (
    <style jsx global>
      {`
        :global(.${RICHTEXT_CE_NAME}) {
        }
      `}
    </style>
  );
}
