import PbContentRichText from "@/components/content/pbContentRichText/pbContentRichText";
import useCesStrRichText from "@/hooks/useCesStrRichText";
import useScopedClassName from "@/hooks/useScopedClassName";
import { ContentElementRichTextStoreSetting } from "@/types/ceSettings/ceSettings";
import { CERichText } from "@/types/content-elements";
import clsx from "clsx";
import React from "react";
import ContentElementWrapper from "../wrapper/contentElementWrapper";
import RichTextContentElementStyleGlobal from "./richTextContentElementStyleGlobal";
import RichTextContentElementStyleScoped from "./richTextContentElementStyleScoped";

export interface RichTextContentElementProps {
  /**
   * Config-Modal settings
   */
  content: CERichText;
  /**
   * position in page content
   */
  position: number;
  /**
   * CE settings
   */
  ceSettings: ContentElementRichTextStoreSetting;
  /**
   * is CE last element in page content
   */
  isLastElement: boolean;
  /**
   * is CE first element in page content
   */
  isFirstElement: boolean;
  /**
   * True in mobile view else false
   */
  isMobile: boolean;
}

export const RICHTEXT_CE_NAME = "richTextCE";

/**
 * RichText
 */
export default React.memo(function RichTextContentElement(
  props: RichTextContentElementProps
) {
  const RICHTEXT_SCOPED_CLASS = useScopedClassName(
    RICHTEXT_CE_NAME,
    props.content,
    props.position
  );

  const { richTextClassName } = useCesStrRichText(
    props.content.cfgStrContentElementSetting
  );

  return (
    <>
      <ContentElementWrapper
        scopedClassName={RICHTEXT_SCOPED_CLASS}
        name={RICHTEXT_CE_NAME}
        content={props.content}
        ceSettings={props.ceSettings}
        isFirstElement={props.isFirstElement}
        isLastElement={props.isLastElement}
        position={props.position}
      >
        <div className={clsx(RICHTEXT_CE_NAME, RICHTEXT_SCOPED_CLASS)}>
          <PbContentRichText
            className={richTextClassName}
            content={props.content.richTextContent}
            cmsFieldContent={"richTextContent"}
            position={props.position}
            ceSettings={props.ceSettings}
          />
        </div>
      </ContentElementWrapper>
      <RichTextContentElementStyleGlobal {...props} />
      <RichTextContentElementStyleScoped
        {...props}
        scopedSelector={RICHTEXT_SCOPED_CLASS}
      />
    </>
  );
});
