import { RichTextContentElementProps } from "./richTextContentElement";

interface RichTextContentElementStyleScopedProps
  extends RichTextContentElementProps {
  scopedSelector: string;
}

/**
 * This is the scoped style from a single CE instance
 * Here you can explicitly style one CE
 */
export default function RichTextContentElementStyleScoped(
  props: RichTextContentElementStyleScopedProps
) {
  return (
    <style jsx global>{`
      :global(.${props.scopedSelector}) {
      }
    `}</style>
  );
}
